const storageService = {
  get(key, fallbackValue) {
    const data = localStorage.getItem(key)

    if (data !== null && data !== undefined) {
      return JSON.parse(data)
    }

    return fallbackValue
  },

  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },

  remove(key) {
    localStorage.removeItem(key)
  }
}

export default storageService
