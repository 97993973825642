import React, { useEffect } from 'react'
import { Redirect, Route } from 'react-router'
import { useUser } from '../../hooks/useUser'
import { routes } from '../../router/routes'
import { socket } from '../../services/ws/socket'

const AppRoute = ({ children, ...rest }) => {
  const { isAuthenticated } = useUser()

  useEffect(() => {
    if (isAuthenticated && !socket.connected) {
      socket.connect()
      console.log('Socket connected')
    }

    return () => {
      socket.disconnect()
      console.log('Socket disconnected')
    }
  }, [isAuthenticated])

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: routes.login,
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

export default AppRoute
