import React, { lazy, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import AppRoute from '../components/AppRoute'
import { routes } from './routes'

const Register = lazy(() => import('../app/Register/Register'))
const PasswordChange = lazy(() => import('../app/Login/PasswordChange'))
const ForgotPassword = lazy(() => import('../app/Login/ForgotPassword'))
const VerifyUser = lazy(() => import('../app/VerifyUser/VerifyUser'))
const Login = lazy(() => import('../app/Login/Login'))
const AppRouter = lazy(() => import('./AppRouter'))
const Unauthorized = lazy(() => import('../components/Unauthorized'))
const PrivacyPolicy = lazy(() => import('../app/Terms/PrivacyPolicy'))
const Terms = lazy(() => import('../app/Terms/Terms'))

export const MainRouter = () => {
  return (
    <Suspense fallback={<div></div>}>
      <Switch>
        <Route path={routes.signUpBrand}>
          <Register mode='brand' />
        </Route>
        <Route path={routes.signUpInfluencer}>
          <Register mode='influencer' />
        </Route>
        <Route path={routes.passwordChange}>
          <PasswordChange />
        </Route>
        <Route path={routes.forgotPassword}>
          <ForgotPassword />
        </Route>
        <Route path={routes.verifyUser}>
          <VerifyUser />
        </Route>
        <Route path={routes.login}>
          <Login />
        </Route>
        <AppRoute path={routes.dashboard}>
          <AppRouter />
        </AppRoute>
        <Route path={routes.home} exact>
          <Redirect to={routes.dashboard} />
        </Route>
        <Route path={routes.privacyPolicy} exact>
          <PrivacyPolicy />
        </Route>
        <Route path={routes.terms} exact>
          <Terms />
        </Route>
        <Route>
          <Unauthorized />
        </Route>
      </Switch>
    </Suspense>
  )
}
