export const routes = {
  login: '/auth/login',
  privacyPolicy: '/privacy-policy',
  terms: '/terms',
  signUpBrand: '/auth/signUp/brand',
  signUpInfluencer: '/auth/signUp/influencer',
  forgotPassword: '/auth/forgot-password',
  passwordChange: '/auth/password-change',
  verifyUser: '/auth/verify',
  dashboard: '/app',
  admin: '/app/admin',
  adminSettings: '/app/admin/settings',
  adminDashboard: '/app/admin/campaigns',
  adminCreateCampaign: '/app/admin/campaigns/create',
  adminViewCampaign: '/app/admin/campaigns/view/:id',
  adminEditCampaign: '/app/admin/campaigns/edit/:id',
  adminChat: '/app/admin/chat',
  adminChatRoom: '/app/admin/chat/:roomId/:targetId',
  adminEvents: '/app/admin/events',
  adminInfluencers: '/app/admin/influencers',
  adminViewInfluencer: '/app/admin/influencers/view/:id',
  adminEditInfluencer: '/app/admin/influencers/edit/:id',
  adminBrands: '/app/admin/brands',
  adminViewBrands: '/app/admin/brands/view/:id',
  adminEditBrands: '/app/admin/brands/edit/:id',
  brand: '/app/brand',
  brandChat: '/app/brand/chat',
  brandDashboard: '/app/brand/campaigns',
  brandCreateCampaign: '/app/brand/campaigns/create',
  brandViewCampaign: '/app/brand/campaigns/view/:id',
  brandEditCampaign: '/app/brand/campaigns/edit/:id',
  brandDiscover: '/app/brand/discover',
  brandProfile: '/app/brand/profile',
  brandEditProfile: '/app/brand/profile/edit',
  brandViewInfluencer: '/app/brand/influencers/view/:id',
  brandAnnouncements: '/app/brand/announcements',
  influencer: '/app/influencer',
  influencerDashboard: '/app/influencer/dashboard',
  influencerDiscover: '/app/influencer/discover',
  influencerProfile: '/app/influencer/profile',
  influencerChat: '/app/influencer/chat',
  influencerEditProfile: '/app/influencer/profile/edit',
  influencerViewCampaign: '/app/influencer/campaign/:id',
  influencerAnnouncements: '/app/influencer/announcements',
  home: '/'
}
