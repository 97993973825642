import { createTheme } from '@mui/material/styles'
import BG from '../assets/bloga-bg.png'

export const theme = createTheme({
  palette: {
    buzzin: {
      main: '#F7941D'
    },
    sunrise6: {
      main: '#FF5757'
    },
    sunrise3: {
      main: '#FFDD4D'
    },
    sunrise2: {
      main: '#FDE481'
    },
    sunrise1: {
      main: '#fdf1bf'
    }
  },
  components: {
    MuiTextField: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .Mui-selected': {
            color: '#F7941D !important'
          }
        },
        indicator: {
          backgroundColor: '#F7941D'
        }
      }
    },
    MuiChip: {
      variants: [
        {
          props: {
            variant: 'sunrise6'
          },
          style: {
            backgroundColor: '#FF5757'
          }
        },
        {
          props: {
            variant: 'buzzin'
          },
          style: {
            backgroundColor: '#F7941D'
          }
        }
      ]
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: `url(${BG})`,
          backgrounPosition: 'center',
          backgroundAttachment: 'fixed',
          backgroundSize: '100% 100%'
        }
      }
    }
  }
})
