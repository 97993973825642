import storageService from '../services/storage'

export const useUser = () => {
  const localUser = storageService.get('user')

  return {
    user: localUser,
    isBrand: localUser?.role === 'BRAND',
    isAdmin: localUser?.role === 'ADMIN',
    isSuper: localUser?.role === 'ADMIN' && localUser?.adminRole === 'SUPER',
    isInfluencer: localUser?.role === 'INFLUENCER',
    isAuthenticated: localUser !== undefined && localUser._id !== undefined
  }
}
